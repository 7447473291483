import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LockIcon from '@mui/icons-material/Lock';
import avatar1 from '../../assests/users/avatar.jpg'
import { toast } from 'react-toastify';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import AccountAuth from '../../state/APIConnect/Account';


const AccountInfo = ({ userInfo }) => {
    let dateCreated = '';
    const [isLoading, setIsLoading] = useState(false);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);

    if (userInfo.dateCreated && new Date(userInfo.dateCreated).getTime()) {
        dateCreated = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short' }).format(new Date(userInfo.dateCreated));
    }

    return (
        <Card>
            <CardContent>
                <Stack spacing={2} sx={{ alignItems: 'center' }}>
                    <Avatar src={avatar1} sx={{ height: '80px', width: '80px' }} />
                    <Button startIcon={<LockIcon fontSize="small" />} onClick={() => setOpenUpdateModal(true)} disabled={isLoading} fullWidth variant="text">Change Password</Button>
                    <UpdatePasswordModal open={openUpdateModal} onClose={() => setOpenUpdateModal(false)} />
                    <Stack spacing={1} sx={{ textAlign: 'center' }}>
                        <Typography variant="h5">{userInfo.firstName}  {userInfo.surname}</Typography>
                        <Typography color="text.secondary" variant="body2">
                           
                        </Typography>
                        <Typography color="text.secondary" variant="body2">
                            {dateCreated}
                        </Typography>
                    </Stack>
                </Stack>
            </CardContent>
            <Divider />
            <CardActions>
                <Button fullWidth variant="contained" disabled>Upload picture</Button>
            </CardActions>
        </Card>
    );
}

export const UpdatePasswordModal = ({ open, onClose }) => {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newpassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const resetPassword = () => {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
    }

    const UpdatePassword = (event) => {
        event.preventDefault();
        setIsLoading(true);
        let data = { "currentPassword": currentPassword, "newPassword": newpassword, "confirmNewPassword": confirmPassword }
        AccountAuth.updatePassword(data).then((response) => {
            setIsLoading(false);
            resetPassword();
            if (response.content.isSuccess) {
                toast.success(response.content.message, { position: "top-right", })
                onClose();
            } else {
                toast.error(response.content.message, { position: "top-right", })
            }
        })
    }

    return (
        <Dialog open={open} >
            <DialogTitle textAlign="center">Update Password</DialogTitle>
            <DialogContent>
                <Stack sx={{ width: '100%', minWidth: { xs: '300px', sm: '360px', md: '400px' }, gap: '1.5rem', paddingTop: '20px' }} >
                    <Box component="form" noValidate sx={{ mt: 1 }} >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField type="password"
                                    required
                                    fullWidth
                                    value={currentPassword}
                                    onChange={e => setCurrentPassword(e.target.value)}
                                    label="Current Password" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField type="password"
                                    required
                                    fullWidth
                                    value={newpassword}
                                    onChange={e => setNewPassword(e.target.value)}
                                    label="Password" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField type="password"
                                    required
                                    fullWidth
                                    value={confirmPassword}
                                    onChange={e => setConfirmPassword(e.target.value)}
                                    label="Confirm Password" />
                            </Grid>
                        </Grid>
                    </Box>
                </Stack>
                {/* <form onSubmit={(e) => e.preventDefault()}>
                Lost your password? Please enter your email address. You will receive a link to create a new password via email.
                <Stack sx={{ width: '100%', minWidth: { xs: '300px', sm: '360px', md: '400px' }, gap: '1.5rem', paddingTop: '20px' }} >
                    <TextField fullWidth label="Email Address" type={'email'} value={emailAddress} onChange={e => setEmailAddress(e.target.value)} />
                    {!isSuccess && message && (<Alert variant="outlined" severity="error"> {message} </Alert>)}
                </Stack>
            </form> */}
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={onClose}>Cancel</Button>
                <Button color="primary" onClick={UpdatePassword} variant="contained" disabled={isLoading}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AccountInfo