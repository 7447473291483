import React, {  useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Tooltip, IconButton, Button } from '@mui/material';
import { ManageAccountsTwoTone } from '@mui/icons-material';
import { useConfirm } from 'material-ui-confirm';
import NSPMonitorAuth from '../../../../state/APIConnect/RaiseMyhand/NSPMonitor';
import Header from '../../../../components/Table/Header';
import { useNavigate } from 'react-router-dom';

const InstitutionsPending = () => {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const confirm = useConfirm();
    const [refresh, setRefresh] = useState(false);

    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const navigate = useNavigate();



    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 10,
            },
            {
                accessorKey: 'name',
                header: 'Name',
                size: 140
            },
            {
                accessorKey: 'description',
                header: 'Description',
                size: 50
            },
            {
                accessorKey: 'province',
                header: 'Province',
                size: 20,
                enableEditing: false,
            },
            {
                accessorKey: 'category',
                header: 'Category',
                size: 50,
                enableEditing: false, //disable editing on this column
            },
            {
                accessorKey: 'districtMunicipality',
                header: 'District Municipality',
                size: 20,
                enableEditing: false,
            },
           
            {
                accessorKey: 'dateCreated',
                header: 'Registration Date',
                size: 150,
                enableEditing: false,
                Cell: ({ cell }) => {
                    const date = new Date(cell.getValue());
                    const options = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
                    return `${date.toDateString()} ${date.toLocaleTimeString([], options)}`;
                }
            },
            // {
            //     accessorKey: 'phoneNumber',
            //     header: 'Phone Number',
            //     size: 20,
            // },
            // {
            //     accessorKey: 'emailAddress',
            //     header: 'Email Address',
            //     size: 20
            // },
            // {
            //     accessorKey: 'alternativeNumber',
            //     header: 'Alternative Number',
            //     size: 20
            // },
        ], [],
    );


    // Handler for opening the edit modal
    const handleReviewInstitution = (row) => {
        navigate("/ReviewInstitution/edit/" + row.id);
    }


    const GetAllUnverifiedInstitutions = async () => {
        if (!tableData.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }
        try {
            const res = await NSPMonitorAuth.GetAllUnverifiedInstitutions(null, pagination.pageIndex, pagination.pageSize);
            setTableData(res.content.items);
            setRowCount(res.content.totalItems)
        } catch (error) {
            console.error(error);
            setIsError(true);
            return;
        } finally {
            setIsError(false);
            setIsLoading(false);
            setIsRefetching(false);
        }
    }

    useEffect(() => {
        GetAllUnverifiedInstitutions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData.length, pagination.pageIndex, pagination.pageSize, refresh])


    return (
        <Box m="0.5rem 0.5rem">
            <MaterialReactTable displayColumnDefOptions={{ 'mrt-row-actions': { muiTableHeadCellProps: { align: 'center', }, size: 120, }, }}
                columns={columns}
                muiTable
                data={tableData}
                editingMode="modal" //default
                initialState={{ columnVisibility: { id: false } }} //hide firstName column by default
                enableColumnOrdering
                enableEditing
                enablePagination
                rowCount={rowCount}
                manualPagination
                onPaginationChange={setPagination}
                muiTableBodyCellEditTextFieldProps={{ multiline: true }}
                state={{
                    isLoading,
                    pagination,
                    showAlertBanner: isError,
                    showProgressBars: isRefetching,
                }}
                positionGlobalFilter="left"
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton color="primary" onClick={() => handleReviewInstitution(row.original)}>
                                <ManageAccountsTwoTone />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                renderTopToolbarCustomActions={() => (
                    <>
                        <Button disabled
                            color="primary"
                            onClick={() => setCreateModalOpen(true)}
                            variant="contained" >
                            Add Institutions
                        </Button>
                        <Header subtitle="Institutions" />
                    </>
                )} />
            
        </Box>
    )
}

export default InstitutionsPending