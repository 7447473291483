import AuthUser from "../../../hooks/AuthUser";
import RestUtilities from '../../RestUtilities';

export default class NSPMonitorAuth {
    //NB: Since MUI Table start at 0 and the API start at 1 we need to add 1    
    static isSignedIn() {
        return !!AuthUser.getToken();
    }

    static async GetAllPillars(platformId) {
        return await RestUtilities.get(`NSPMonitor/GetPillars?platformId=${platformId}`);
    }

    static async GetPillarsForInstitutions() {
        return await RestUtilities.get(`NSPMonitor/GetPillarsForInstitutions`);
    }

    static async FindInstitutionByRegistrationNumber(registrationNumber) {
        return await RestUtilities.get(`NSPMonitor/FindInstitutionByRegistrationNumber?registrationNumber=${registrationNumber}`);
    }
    
    static async GetAllIntervention(searchString, platformId, pageNumber, pageSize) {
        //Since MUI Table start at 0 and the API start at 1 we need to add 1
        let correctPageNumber = pageNumber + 1;
        if (searchString === null || searchString === undefined) {
            return await RestUtilities.get(`NSPMonitor/GetAllIntervention?platformId=${platformId}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
        }
        return await RestUtilities.get(`NSPMonitor/GetAllIntervention?searchString=${searchString}&platformId=${platformId}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
    }

    static async GetAllInstitutions(searchString, pageNumber, pageSize) {
        let correctPageNumber = pageNumber + 1;
        if (searchString === null || searchString === undefined) {
            return await RestUtilities.get(`NSPMonitor/GetAllInstitutions?pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
        }
        return await RestUtilities.get(`NSPMonitor/GetAllInstitutions?searchString=${searchString}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
    }

    static async GetAllActivies(searchString, platformId, pageNumber, pageSize) {
        let correctPageNumber = pageNumber + 1;
        if (searchString === null || searchString === undefined) {
            return await RestUtilities.get(`NSPMonitor/GetAllActivies?platformId=${platformId}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
        }
        return await RestUtilities.get(`NSPMonitor/GetAllActivies?searchString=${searchString}&platformId=${platformId}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
    }

    static async GetAllQuarterlyAchievements(searchString, pageNumber, pageSize) {
        let correctPageNumber = pageNumber + 1;
        if (searchString === null || searchString === undefined) {
            return await RestUtilities.get(`NSPMonitor/GetAllQuarterlyAchievements?pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
        }
        return await RestUtilities.get(`NSPMonitor/GetAllQuarterlyAchievements?searchString=${searchString}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
    }


    static async GetAllUnverifiedInstitutions(searchString, pageNumber, pageSize) {
        let correctPageNumber = pageNumber + 1;
        if (searchString === null || searchString === undefined) {
            return await RestUtilities.get(`NSPMonitor/GetAllUnverifiedInstitutions?pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
        }
        return await RestUtilities.get(`NSPMonitor/GetAllUnverifiedInstitutions?searchString=${searchString}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
    }

    static async GetUnSelectedInterventionsForPillar(pillarId) {
        return await RestUtilities.get(`NSPMonitor/GetUnSelectedInterventionsForPillar?pillarId=${pillarId}`);
    }

    static async UpsertPillarInterventions(data) {
        return await RestUtilities.post(`NSPMonitor/UpsertPillarIntervention`, data);
    }

    static async UpsertSubActivity(data) {
        return await RestUtilities.post(`NSPMonitor/UpsertSubActivity`, data);
    }

    static async UpsertInterventionActivity(data) {
        return await RestUtilities.post(`NSPMonitor/AddInterventionActivity`, data);
    }

    static async UpsertActivitySubActivity(data) {
        return await RestUtilities.post(`NSPMonitor/AddActivitySubActivity`, data);
    }

    static async UpsertAchievement(data) {
        return await RestUtilities.post(`NSPMonitor/UpsertAchievement`, data);
    }

    static async UpsertInstitution(data) {
        return await RestUtilities.post(`NSPMonitor/UpsertInstitution`, data);
    }

    static async GetAllPillarInterventions(searchString, platformId, pageNumber, pageSize) {
        let correctPageNumber = pageNumber + 1;
        if (searchString === null || searchString === undefined) {
            return await RestUtilities.get(`NSPMonitor/GetPillarInterventions?platformId=${platformId}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
        }
        return await RestUtilities.get(`NSPMonitor/GetPillarInterventions?searchString=${searchString}&platformId=${platformId}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
    }

    static async GetAllActivitySubActivity(searchString, platformId, pageNumber, pageSize) {
        let correctPageNumber = pageNumber + 1;
        if (searchString === null || searchString === undefined) {
            return await RestUtilities.get(`NSPMonitor/GetAllActivitiesSubActivity?platformId=${platformId}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
        }
        return await RestUtilities.get(`NSPMonitor/GetAllActivitiesSubActivity?searchString=${searchString}&platformId=${platformId}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
    }

    static async GetInterventionActivity(searchString, platformId, pageNumber, pageSize) {
        let correctPageNumber = pageNumber + 1;
        if (searchString === null || searchString === undefined) {
            return await RestUtilities.get(`NSPMonitor/GetInterventionActivity?platformId=${platformId}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
        }
        return await RestUtilities.get(`NSPMonitor/GetInterventionActivity?searchString=${searchString}&platformId=${platformId}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
    }

    static async GetInterventionsByPillarId(pillarId, searchString, pageNumber, pageSize) {
        let correctPageNumber = pageNumber + 1;
        if (searchString === null || searchString === undefined) {
            return await RestUtilities.get(`NSPMonitor/GetInterventionsByPillarId?pillarId=${pillarId}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
        }
        return await RestUtilities.get(`NSPMonitor/GetInterventionsByPillarId?searchString=${searchString}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
    }

    static async GetActivityByInterventionId(interventionId, searchString, pageNumber, pageSize) {
        let correctPageNumber = pageNumber + 1;
        if (searchString === null || searchString === undefined) {
            return await RestUtilities.get(`NSPMonitor/GetActivitiesByInterventionId?interventionId=${interventionId}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
        }
        return await RestUtilities.get(`NSPMonitor/GetActivitiesByInterventionId?interventionId=${interventionId}&searchString=${searchString}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
    }

    static async GetSubActivityByActivityId(activityId, searchString, pageNumber, pageSize) {
        let correctPageNumber = pageNumber + 1;
        if (searchString === null || searchString === undefined) {
            return await RestUtilities.get(`NSPMonitor/GetSubActivitiesByActivityId?activityId=${activityId}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
        }
        return await RestUtilities.get(`NSPMonitor/GetSubActivitiesByActivityId?activityId=${activityId}&searchString=${searchString}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
    }

    static async GetAllStatus() {
        return await RestUtilities.get(`NSPMonitor/GetAllStatus`);
    }

    static async GetAllDeviationReason() {
        return await RestUtilities.get(`NSPMonitor/GetAllDeviationReason`);
    }

    static async GetAllSubActivities(searchString, platformId, pageNumber, pageSize) {
        let correctPageNumber = pageNumber + 1;
        if (searchString === null || searchString === undefined) {
            return await RestUtilities.get(`NSPMonitor/GetAllSubActivities?platformId=${platformId}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
        }
        return await RestUtilities.get(`NSPMonitor/GetAllSubActivities?searchString=${searchString}&platformId=${platformId}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
    }

    static async UpsertIntervention(data) {
        return await RestUtilities.post(`NSPMonitor/UpsertIntervention`, data);
    }

    static async UpsertActivity(data) {
        return await RestUtilities.post(`NSPMonitor/UpsertActivity`, data);
    }

    static async GetTotaltInterventionReport(platformId) {
        return await RestUtilities.get(`NSPMonitor/GetInterventionReport?platformId=${platformId}`);
    }

    static async GetMainDashBoardReport(platformId) {
        return await RestUtilities.get(`NSPMonitor/MainDashBoardReport?platformId=${platformId}`);
    }

    static async GetTotalPillarAchievementReport(platformId) {
        return await RestUtilities.get(`NSPMonitor/GetTotalPillarAchievementReport?platformId=${platformId}`);
    }

    static async GetTotalAtctivityReport(platformId) {
        return await RestUtilities.get(`NSPMonitor/GetActivityReport?platformId=${platformId}`);
    }

    static async GetTotalInstitutionProvincesReport(platformId) {
        return await RestUtilities.get(`NSPMonitor/GetTotalInstitutionProvincesReport?platformId=${platformId}`);
    }

    static async GetTotaltSubActivityReport(platformId) {
        return await RestUtilities.get(`NSPMonitor/GetSubActivityReport?platformId=${platformId}`);
    }

    static async GetAchievementReport() {
        return await RestUtilities.get(`NSPMonitor/GetAchievementReport`);
    }

    static async DeleteActivitySubActivity(data) {
        return await RestUtilities.delete(`NSPMonitor/DeleteActivitySubActivity`, data);
    }

    static async DeleteInterventionActivity(data) {
        return await RestUtilities.delete(`NSPMonitor/DeleteInterventionActivity`, data);
    }

    static async DeletePillarInterventionById(pillarInterventionId) {
        return await RestUtilities.delete(`NSPMonitor/DeletePillarInterventionById?pillarInterventionId=${pillarInterventionId}`);
    }

    static async DeleteInterventionById(interventionId) {
        return await RestUtilities.delete(`NSPMonitor/DeleteInterventionById?interventionId=${interventionId}`);
    }

    static async DeleteActivityById(activityId) {
        return await RestUtilities.delete(`NSPMonitor/DeleteActivityById?activityId=${activityId}`);
    }

    static async GetActiviyById(activityId) {
        return await RestUtilities.get(`NSPMonitor/GetActiviyById?activityId=${activityId}`);
    }

    static async GetAllAchievementsByStatus(searchString, pageNumber, pageSize, status) {
        let correctPageNumber = pageNumber + 1;
        if (searchString === null || searchString === undefined) {
            return await RestUtilities.get(`NSPMonitor/GetAllAchievementsByStatus?pageNumber=${correctPageNumber}&pageSize=${pageSize}&status=${status}`);
        }
        return await RestUtilities.get(`NSPMonitor/GetAllAchievementsByStatus?searchString=${searchString}&pageNumber=${correctPageNumber}&pageSize=${pageSize}&status=${status}`);
    }

    static async GetAchievementById(achievementId) {
        return await RestUtilities.get(`NSPMonitor/GetAchievementById?AchievementId=${achievementId}`);
    }

    static async GetAllCategories(searchString, pageNumber, pageSize) {
        let correctPageNumber = pageNumber + 1;
        if (searchString === null || searchString === undefined) {
            return await RestUtilities.get(`NSPMonitor/GetAllCategories?pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
        }
        return await RestUtilities.get(`NSPMonitor/GetAllCategories?searchString=${searchString}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
    }

    static async DeleteInstitutionById(institutionId) {
        return await RestUtilities.delete(`NSPMonitor/DeleteInstitutionById?institutionId=${institutionId}`);
    }

    static async GetAllInstitutionForMapDisplay() {
        return await RestUtilities.get(`NSPMonitor/GetAllInstitutionForMapDisplay`);
    }

    static async GetAllCatergories() {
        return await RestUtilities.get(`NSPMonitor/GetAllCatergories`);
    }

    static async GetQaurterlyReport(cyclicId, reportDate, pillarId, interventationId, activitiesId) {
        return await RestUtilities.get(`NSPMonitor/GetQaurterlyReport?cyclicId=${cyclicId}
        &reportDate=${reportDate}&pillarId=${pillarId}&interventationId=${interventationId}&activitiesId=${activitiesId}`);
    }

    static async UpsertQaurterlyReport(data) {
        return await RestUtilities.postForm(`NSPMonitor/UpsertQaurterlyReport`, data);
    }    

    static async RegisterInstitution(data) {
        return await RestUtilities.postForm(`NSPMonitor/AddInstitution`, data);
    }

    static async GetQaurterlyReportByAchievementById(achievementId) {
        return await RestUtilities.get(`NSPMonitor/GetQaurterlyReportByAchievementById?AchievementId=${achievementId}`);
    }

    static async GetInstitutionProvinceReportByProvinceId(searchString, provinceId, pageNumber, pageSize) {
        let correctPageNumber = pageNumber + 1;
        if (searchString === null || searchString === undefined) {
            return await RestUtilities.get(`NSPMonitor/GetInstitutionProvinceReportByProvinceId?provinceId=${provinceId}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
        }
        return await RestUtilities.get(`NSPMonitor/GetInstitutionProvinceReportByProvinceId?searchString=${searchString}&provinceId=${provinceId}&&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
    }

    static async GetAchievementDeviationReason() {
        return await RestUtilities.get(`NSPMonitor/GetAchievementDeviationReason`);
    }

    static async GetAchievementStatusReport() {
        return await RestUtilities.get(`NSPMonitor/GetAchievementStatusReport`);
    }

    static async GetInstitutionById(institutionId) {
        return await RestUtilities.get(`NSPMonitor/GetInstitutionById?InstitutionId=${institutionId}`);
    }

    static async ReviewInstitution(data) {
        return await RestUtilities.patch(`NSPMonitor/ReviewInstitution`, data);
    }

    static async GetAllOrganizationType() {
        return await RestUtilities.get(`NSPMonitor/GetAllOrganizationType`);
    }

    static async GetInstitutionVerificationStatusByInstitutionId(institutionId) {
        return await RestUtilities.get(`NSPMonitor/GetInstitutionVerificationStatusByInstitutionId?InstitutionId=${institutionId}`);
    }

}