import React, { useMemo } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';
import { themeSettings } from './theme';
import MainLayout from './components/MainLayout';
import SideLogin from './pages/Account/SideLogin';
import SideSignUp from './pages/Account/SideSignUp';
import { unstable_createMuiStrictModeTheme } from '@mui/material/styles';
import Activities from './pages/RaiseMyHand/NSPMonitor/Activity';
import Intervention from './pages/RaiseMyHand/NSPMonitor/Intervention';
import SetNewPassword from './pages/Account/SetNewPassword';
import SubActivity from './pages/RaiseMyHand/NSPMonitor/SubActivity';
import AddMonthlyAchievement from './pages/RaiseMyHand/NSPMonitor/Achievement/AddMonthlyAchievement';
import EditMonthlyAchievement from './pages/RaiseMyHand/NSPMonitor/Achievement/EditMonthlyAchievement';
import MonthlyAchievement from './pages/RaiseMyHand/NSPMonitor/Achievement/MonthlyAchievement';
import Example from './pages/Example';
import HIVActivities from './pages/RaiseMyHand/HIVandAids/Activity';
import HIVIntervention from './pages/RaiseMyHand/HIVandAids/Intervention';
import HIVSubActivity from './pages/RaiseMyHand/HIVandAids/SubActivity';
import UsersPage from './pages/Account/UsersPage';
import Dashboard from './pages/Home/Dashboard';
import InstitutionPage from './pages/RaiseMyHand/NSPMonitor/Institution/InstitutionPage';
import Footer from './pages/Home/Footer';
import AddQuarterlyAchievement from './pages/RaiseMyHand/NSPMonitor/QuarterlyAchievement/AddQuarterlyAchievement';
import QuarterlyAchievement from './pages/RaiseMyHand/NSPMonitor/QuarterlyAchievement/QuarterlyAchievement';
import EditQuarterlyAchievement from './pages/RaiseMyHand/NSPMonitor/QuarterlyAchievement/EditQuarterlyAchievement';
import NotFound from './pages/Home/NotFound';
import BPActivities from './pages/RaiseMyHand/BeyondPresidential/Activity';
import BPIntervention from './pages/RaiseMyHand/BeyondPresidential/Intervention';
import BPSubActivity from './pages/RaiseMyHand/BeyondPresidential/SubActivity';
import SummitResolutionProgressReport from './pages/RaiseMyHand/BeyondPresidential/Reports/SummitResolutionProgressReport';
import UsersPendingApprovals from './pages/Account/UsersPendingApprovals';
import NSPUsersPage from './pages/Account/NSPUsersPage';
import AccountPage from './pages/Account/AccountPage';
import AddInstitutionPage from './pages/RaiseMyHand/NSPMonitor/Institution/AddInstitutionPage';
import InstitutionsPending from './pages/RaiseMyHand/NSPMonitor/Institution/InstitutionsPending';
import ReviewInstitutionPage from './pages/RaiseMyHand/NSPMonitor/Institution/ReviewInstitutionPage';
import ConfirmEmailPage from './pages/Account/ConfirmEmailPage';
import CompletedAchievements from './pages/RaiseMyHand/NSPMonitor/Achievement/CompletedAchievements';
import InProgressAchievements from './pages/RaiseMyHand/NSPMonitor/Achievement/InProgressAchievements';
import DoneAchievements from './pages/RaiseMyHand/NSPMonitor/Achievement/DoneAchievements';

function App() {
  const mode = useSelector((state) => state.global.mode);
  //const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  useMemo(() => createTheme(themeSettings(mode)), [mode]);

  //const theme = createTheme({ palette: { mode: 'dark', }, });
  const defaultTheme = unstable_createMuiStrictModeTheme();

  return (
    <div className="App">
      <BrowserRouter>
        <ThemeProvider theme={defaultTheme}>
          <CssBaseline />

          <Routes>
            <Route element={<MainLayout />}>
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path='/userprofile' element={<AccountPage />} />
              <Route path='/login' element={<SideLogin />} />
              <Route path='/signUp' element={<SideSignUp />} />
              <Route path='/setNewPassword' element={<SetNewPassword />} />
              <Route path='/users' element={<UsersPage />} />
              <Route path='/userspendingapprovals' element={<UsersPendingApprovals />} />
              <Route path='/pendingInstitutions' element={<InstitutionsPending />} />
              <Route path='/nspUsers' element={<NSPUsersPage />} />
              <Route path='/interventionPillars' element={<Intervention />} />
              <Route path='/activityIntervention' element={<Activities />} />
              <Route path='/subActivityActivity' element={<SubActivity />} />
              <Route path='/monthlyAchievement/InProgress' element={<InProgressAchievements />} />
              <Route path='/monthlyAchievement/Completed' element={<CompletedAchievements />} />
              <Route path='/monthlyAchievement/Done' element={<DoneAchievements />} />
              <Route path='/monthlyAchievement/create' element={<AddMonthlyAchievement />} />
              <Route path='/institution' element={<InstitutionPage />} />
              <Route path='/monthlyAchievement/edit/:achievementId' element={<EditMonthlyAchievement />} />
              <Route path='/reviewInstitution/edit/:institutionId' element={<ReviewInstitutionPage />} />
              <Route path='/hivInterventionPillar' element={<HIVIntervention />} />
              <Route path='/hivActivityIntervention' element={<HIVActivities />} />
              <Route path='/hivSubActivityActivity' element={<HIVSubActivity />} />
              <Route path='/registerInstitution' element={<AddInstitutionPage />} />
              {/* <Route path='/addInstitution' element={<AddInstitutionPage />} /> */}
              <Route path='/quarterlyAchievement/create' element={<AddQuarterlyAchievement />} />
              <Route path='/quarterlyAchievement' element={<QuarterlyAchievement />} />
              <Route path='/quarterlyAchievement/edit/:achievementId' element={<EditQuarterlyAchievement />} />
              <Route path='/bpActivities' element={<BPActivities />} />
              <Route path='/bpInterventionPillar' element={<BPIntervention />} />
              <Route path='/bpSubActivityActivity' element={<BPSubActivity />} />
              <Route path='/summitResolutionProgressReport' element={<SummitResolutionProgressReport />} />
              <Route path="/Account/ConfirmEmail" element={<ConfirmEmailPage />} />
              <Route path='/example' element={<Example />} />
              <Route path='*' element={<NotFound />} />
            </Route>
          </Routes>
        </ThemeProvider>
        <Footer title="KDBS Solutions" description="Enabling Data Driven Collaborations" />
      </BrowserRouter>
    </div >
  );
}

export default App;
