import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { Stack, TextField, Tooltip, IconButton, Button } from '@mui/material';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';
import NSPMonitorAuth from '../../../../state/APIConnect/RaiseMyhand/NSPMonitor';
import Header from '../../../../components/Table/Header';

const BPActivityPage = () => {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const confirm = useConfirm();
    const [refresh, setRefresh] = useState(false);
    let platformId = 3;

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        try {
            const resp = await NSPMonitorAuth.UpsertActivity(values);
            if (!resp.is_error || resp.content.isSuccess) {
                toast.success(resp.content.message, { position: "top-right", });
                if (!Object.keys(validationErrors).length) {
                    tableData[row.index] = values;
                    setTableData([...tableData]);
                    exitEditingMode();
                }
            } else {
                toast.error(resp.content.message, { position: "top-right", })
            }
        } catch (error) {
            toast.error(error, { position: "top-right", })
        }
    };

    const handleDeleteRow = useCallback((row) => {
        confirm({
            title: `Are you sure you want to delete?`, description: `This will permanently delete: 
            ${row.original.name}.`, cancellationText: `No`, confirmationText: `Yes`
        }).then(async () => {
            setIsLoading(true);
            const resp = await NSPMonitorAuth.DeleteActivityById(row.original.id);
            setIsLoading(false);
            if (resp.content === "") {
                toast.error("Error occured while attemping to Delele.Please Contact Admininstrator", { position: "top-right", })
            }
            if (resp.content?.isSuccess) {
                setRefresh(!refresh);
                toast.success(resp.content.message, { position: "top-right", });
            } else {
                toast.error(resp.content.message, { position: "top-right", })
            }
        }).catch(error => {
            console.log("Deletion cancelled." + error)
            toast.error(error, { position: "top-right", })
        });
        tableData.splice(row.index, 1);
        setTableData([...tableData]);
    }, [confirm, refresh, tableData]);

    const GetAllIntervention = async () => {
        if (!tableData.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }
        try {
            const res = await NSPMonitorAuth.GetAllActivies(null, platformId, pagination.pageIndex, pagination.pageSize);
            setTableData(res.content.items);
            setRowCount(res.content.totalItems)
        } catch (error) {
            setIsError(true);
            console.error(error);
            return;
        } finally {
            setIsError(false);
            setIsLoading(false);
            setIsRefetching(false);
        }
    }

    useEffect(() => {
        GetAllIntervention();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData.length, pagination.pageIndex, pagination.pageSize, refresh])

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid = true;
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 10,
            },
            {
                accessorKey: 'prefix',
                header: 'Prefix',
                size: 10,
                enableEditing: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'name',
                header: 'Name',
                size: 450,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'indicators',
                header: 'Indicator',
                size: 80,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'target',
                header: 'Target',
                size: 10,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                    type: 'number',
                }),
            },
        ], [getCommonEditTextFieldProps],
    );

  return (
    <>
        <MaterialReactTable displayColumnDefOptions={{ 'mrt-row-actions': { muiTableHeadCellProps: { align: 'center', }, size: 120, }, }}
            columns={columns}
            muiTable
            data={tableData}
            editingMode="modal" //default
            initialState={{ columnVisibility: { id: true } }} //hide firstName column by default
            enableColumnOrdering
            enableEditing
            enablePagination
            rowCount={rowCount}
            manualPagination
            onPaginationChange={setPagination}
            muiTableBodyCellEditTextFieldProps={{ multiline: true }}
            onEditingRowSave={handleSaveRowEdits}
            onEditingRowCancel={handleCancelRowEdits}
            state={{
                isLoading,
                pagination,
                showAlertBanner: isError,
                showProgressBars: isRefetching,
            }}
            positionGlobalFilter="left"
            renderRowActions={({ row, table }) => (
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <Tooltip arrow placement="left" title="Edit">
                        <IconButton onClick={() => table.setEditingRow(row)}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip arrow placement="right" title="Delete">
                        <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </Box>
            )}
            renderTopToolbarCustomActions={() => (
                <>
                    <Button
                        color="primary"
                        onClick={() => setCreateModalOpen(true)}
                        variant="contained" >
                        Add Activity
                    </Button>
                    <Header subtitle="Activities" />
                </>
            )} />

        <CreateNewAccountModal
            open={createModalOpen}
            onClose={() => setCreateModalOpen(false)}
            refresh={refresh}
            platformId={platformId}
            setRefresh={() => setRefresh(!refresh)} />
    </>
)
}

export const CreateNewAccountModal = ({ open, onClose, refresh, platformId, setRefresh }) => {
    const [name, setName] = useState('');
    const [indicator, setIndicator] = useState('');
    const [target, setTarget] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const isFormValid = () => {
        let valid = true;
        if (name === '' || name.length < 7) {
            valid = false;
        }
        return valid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const data = { "id": 0, "platformId": platformId, "name": name, "indicators": indicator, "target": target, "isSubActivities": false };
            const resp = await NSPMonitorAuth.UpsertActivity(data);
            setIsLoading(false);
            if (!resp.is_error || resp.content.isSuccess) {
                toast.success(resp.content.message, { position: "top-right", })
                setRefresh(!refresh);
                setName('');
                setIndicator('');
                setTarget(0);
                onClose();
            } else {
                toast.error(resp.content.message, { position: "top-right", })
            }
        } catch (error) {
            setIsLoading(false);
            toast.error(error, { position: "top-right", })
        }

    };


    return (
        <>
            <Dialog open={open} fullWidth>
                <DialogTitle textAlign="center">Create New Activity</DialogTitle>
                <DialogContent>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <Stack sx={{ width: '100%', minWidth: { xs: '300px', sm: '360px', md: '400px' }, gap: '1.5rem', paddingTop: '10px' }}>

                            <TextField fullWidth multiline rows={2} label="Activity Name" value={name} onChange={e => setName(e.target.value)} />

                            <TextField fullWidth multiline rows={2} label="Indicator" value={indicator} onChange={e => setIndicator(e.target.value)} />

                            <TextField fullWidth label="Target" type={'number'} value={target} onChange={e => setTarget(e.target.value)} />

                        </Stack>
                    </form>
                </DialogContent>
                <DialogActions sx={{ p: '1.25rem' }}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button color="primary" onClick={handleSubmit} variant="contained" disabled={isLoading || !isFormValid()}>
                        Create New Activity
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};


export default BPActivityPage