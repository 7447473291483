import React, { useState } from 'react'
import { Button, CardActions, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material'
import { Card, CardHeader, Divider, CardContent, Grid } from '@mui/material'
import AccountAuth from '../../state/APIConnect/Account';
import { toast } from 'react-toastify';


const AccountForm = ({ userInfo }) => {
    const [firstName, setFirstName] = useState(userInfo?.firstName ?? '');
    const [lastName, setLastName] = useState(userInfo?.surname);
    const [mobileNumber, setMobileNumber] = useState(userInfo?.mobileNumber);
    const [emailAddress, setEmailAddress] = useState(userInfo?.emailAddress);
    const [institutionName, setInstitutionName] = useState(userInfo?.institutionName);
    const [institutionId, setInstitutionId] = useState(userInfo?.institutionId);
    const [roles, setRoles] = useState(userInfo?.roles);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        setIsLoading(true);

        let bodyParameters = {
            "firstName": firstName,
            "surname": lastName,
            "mobileNumber": mobileNumber,
            "emailAddress": emailAddress,
            "institutionId": institutionId
        }
        setIsLoading(true);
        await AccountAuth.UpdateProfile(bodyParameters).then((response) => {
            setIsLoading(false);
            if (response.content === null) {
                toast.error(response.error_content.message, { position: "top-right", })
            }
            else {
                toast.success(response.content.message, { position: "top-right", });
            }
        })
    };

    const isFormValid = () => {
        return firstName !== '' && lastName !== '' && mobileNumber !== '';
    }

    return (
        <Card>
            <CardHeader subheader="The information can be edited" title="Profile" />
            <Divider />
            <CardContent>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={6}>
                        <TextField required
                            fullWidth
                            onChange={e => setFirstName(e.target.value)}
                            label="First Name"
                            value={firstName}
                            autoFocus />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField required
                            fullWidth
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                            label="Last Name" />
                    </Grid>


                    <Grid item xs={12} sm={6}>
                        <TextField required
                            type="tel"
                            fullWidth
                            value={mobileNumber}
                            onChange={e => setMobileNumber(e.target.value)}
                            label="Mobile Number" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField disabled
                            required
                            type="email"
                            value={emailAddress}
                            fullWidth
                            onChange={e => setEmailAddress(e.target.value)}
                            label="Email Address" />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField disabled
                            required
                            type="email"
                            value={institutionName}
                            fullWidth
                            onChange={e => setInstitutionName(e.target.value)}
                            label="Institution Name" />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="role-select-label">Roles</InputLabel>
                            <Select labelId="role-select-label"
                                multiple
                                input={<OutlinedInput label="Roles" />}
                                value={roles.map(role => role.roleId)}
                                renderValue={(selected) => selected.map(roleId => roles.find(role => role.roleId === roleId).roleName).join(', ')}
                                disabled>
                                {roles.map((role) => (
                                    <MenuItem key={role.roleId} value={role.roleId}>
                                        {role.roleName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>


                </Grid>
            </CardContent>
            <Divider />
            <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button variant="contained" disabled={!isFormValid() || isLoading} onClick={handleSubmit}>Save details</Button>
            </CardActions>
        </Card>
    )
}

export default AccountForm