import React, { useState, useEffect, useRef } from 'react';
import { TextField } from '@mui/material';

const GoogleAddressAutoComplete = ({ value, onChange }) => {
    const [inputValue, setInputValue] = useState(value || '');
    const [autocomplete, setAutocomplete] = useState(null);
    const autocompleteRef = useRef(null);

    // Sync the input value with the value prop when it changes
    useEffect(() => {
        setInputValue(value || '');
    }, [value]);

    // Initialize Google Autocomplete
    useEffect(() => {
        if (window.google) {
            const options = {
                types: ['address'],
                componentRestrictions: { country: 'za' }, // Restrict results to South Africa
            };
            setAutocomplete(new window.google.maps.places.Autocomplete(autocompleteRef.current, options));
        }
    }, []);

    // Listen for place selection from Google Autocomplete
    useEffect(() => {
        if (autocomplete) {
            autocomplete.addListener('place_changed', handlePlaceSelect);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autocomplete]);

    // Handle the place selection
    const handlePlaceSelect = () => {
        const place = autocomplete.getPlace();
        if (place && place.formatted_address) {
            setInputValue(place.formatted_address); // Update input value
            onChange(place.formatted_address); // Propagate the change to the parent
        }
    };

    // Handle manual input changes
    const handleChange = (e) => {
        setInputValue(e.target.value);
        onChange(e.target.value); // Update parent with the manually entered value
    };

    return (
        <TextField
            fullWidth
            label="Address"
            inputRef={autocompleteRef}
            value={inputValue}  // Always use controlled value
            onChange={handleChange}
            required
        />
    );
};

export default GoogleAddressAutoComplete;
