import React, { useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { Stack, Dialog, Grid, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { DialogActions, DialogContent, Box, DialogTitle, OutlinedInput, FormControlLabel } from '@mui/material';
import { TextField, Tooltip, IconButton, Button, ListItemText, Checkbox } from '@mui/material';
import { ManageAccountsTwoTone } from '@mui/icons-material';
import { useConfirm } from 'material-ui-confirm';
import Header from '../../components/MainLayout/Header';
import validator from 'validator';
import { toast } from 'react-toastify';
import { AppSettings } from '../../AppSettings';
import AccountAuth from '../../state/APIConnect/Account';
import NSPMonitorAuth from '../../state/APIConnect/RaiseMyhand/NSPMonitor';

const UsersPendingApprovals = () => {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [refresh, setRefresh] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [roles, setRoles] = useState([]);

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    // Handler for opening the edit modal
    const handleOpenEditModal = (item) => {
        setSelectedItem(item.original);
        setEditModalOpen(true);
    };

    // Handler for closing any modal
    const handleCloseModal = () => {
        setCreateModalOpen(false);
        setEditModalOpen(false);
        setSelectedItem(null);
    };


    const GetAllUsers = async () => {
        if (!tableData.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }

        try {
            const res = await AccountAuth.GetAllPendingApprovalUsersInNSPMonitor(null, pagination.pageIndex, pagination.pageSize);
            setTableData(res.content.items);
            setRowCount(res.content.totalItems)
        } catch (error) {
            setIsError(true);
            console.error(error);
            return;
        } finally {
            setIsError(false);
            setIsLoading(false);
            setIsRefetching(false);
        }
    }



    const GetAllRoles = async () => {
        const res = await AccountAuth.GetAllRolesByAppId();
        setRoles(res.content.filter(role => role.roleName !== 'General'));
    }

    useEffect(() => {
        GetAllUsers();
        GetAllRoles();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData.length, pagination.pageIndex, pagination.pageSize, refresh])

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 10
            },
            {
                accessorKey: 'institutionName',
                header: 'Institution Name',
                size: 10
            },
            {
                accessorKey: 'firstName',
                header: 'First Name',
                size: 10
            },
            {
                accessorKey: 'surname',
                header: 'Surname',
                size: 10
            },
            {
                accessorKey: 'mobileNumber',
                header: 'Mobile Number',
                size: 20
            },
            {
                accessorKey: 'emailAddress',
                header: 'Email Address',
                size: 20,
                enableEditing: false
            },
            {
                accessorKey: 'userRoles',
                header: 'Role',
                editVariant: 'select',
                editSelectOptions: AppSettings.AdminRoles,
                muiEditTextFieldProps: {
                    select: true,
                    error: !!validationErrors?.userRoles,
                    helperText: validationErrors?.userRoles,
                },
            },
            {
                accessorKey: 'dateCreated',
                header: 'Registered Date',
                size: 150,
                enableEditing: false,
                Cell: ({ cell }) => {
                    const date = new Date(cell.getValue());
                    const options = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
                    return `${date.toDateString()} ${date.toLocaleTimeString([], options)}`;
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        ], []
    );

    return (
        <Box m="0.5rem 0.5rem">
            <MaterialReactTable displayColumnDefOptions={{ 'mrt-row-actions': { muiTableHeadCellProps: { align: 'center', }, size: 120, }, }}
                columns={columns}
                muiTable
                data={tableData}
                editingMode="modal" //default
                initialState={{ columnVisibility: { id: false } }} //hide firstName column by default
                enableColumnOrdering
                enableEditing
                enablePagination
                rowCount={rowCount}
                manualPagination
                onPaginationChange={setPagination}
                muiTableBodyCellEditTextFieldProps={{ multiline: true }}
                onEditingRowCancel={handleCancelRowEdits}
                state={{
                    isLoading,
                    pagination,
                    showAlertBanner: isError,
                    showProgressBars: isRefetching,
                }}
                positionGlobalFilter="left"
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton color="primary" onClick={() => handleOpenEditModal(row)}>
                                <ManageAccountsTwoTone />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                renderTopToolbarCustomActions={() => (
                    <>
                        <Button disabled
                            color="primary"
                            onClick={() => setCreateModalOpen(true)}
                            variant="contained" >
                            Add New User
                        </Button>
                        <Header subtitle="Add New User" />
                    </>
                )} />

            <CreateNewAccountModal
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                refresh={refresh}
                setRefresh={() => setRefresh(!refresh)}
                roles={roles} />

            {isEditModalOpen && selectedItem && (
                <CreateNewAccountModal
                    open={isEditModalOpen}
                    onClose={handleCloseModal}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    roles={roles}
                    selectedItem={selectedItem}
                    editId={selectedItem.id}
                />
            )}
        </Box>
    )
}

export const CreateNewAccountModal = ({ open, onClose, refresh, setRefresh, roles, selectedItem, editId = 0 }) => {
    const [firstName, setFirstName] = useState(selectedItem?.firstName || '');
    // const [institutionId, setInstitutionId] = useState(selectedItem?.institutionId || 0);
    // const [appId, setAppId] = useState(selectedItem?.appId || AppSettings.App.NSPMonitoring);
    const [surname, setSurname] = useState(selectedItem?.surname || '');
    const [mobileNumber, setMobileNumber] = useState(selectedItem?.mobileNumber || '');
    const [emailAddress, setEmailAddress] = useState(selectedItem?.emailAddress || '');
    const [userRoles, setUserRoles] = useState([]);
    const [pillarRoles, setPillarRoles] = useState([]);
    const [pillars, setPillars] = useState([]);
    const [validationError, setValidationError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [displayPillars, setDisplayPillars] = useState(false);

    useEffect(() => {
        if (userRoles.includes('Report Capture') || userRoles.includes('Report Approver')) {
            GetAllPillars();
            setDisplayPillars(true);
        } else {
            setDisplayPillars(false);
        }
    }, [userRoles]);

    const GetAllPillars = async () => {
        const res = await NSPMonitorAuth.GetAllPillars(AppSettings.Platform.NSPMonitor);
        setPillars(res.content);
    };

    const handleChange = (event) => {
        const { target: { value }, } = event;
        setUserRoles(typeof value === 'string' ? value.split(',') : value);
        if (!userRoles.includes('Report Capture') || userRoles.includes('Report Approver')) {
            setDisplayPillars(false);
        }
    };

    const handleChangePillars = (event) => {
        const { target: { value }, } = event;
        setPillarRoles(typeof value === 'string' ? value.split(',') : value);
    };

    const isFormValid = () => {
        let valid = true;
        // if (name === '' || name.length < 7) {
        //     valid = false;
        // }
        return valid;
    }

    const onResetFormAndClose = () => {
        setFirstName('');
        setSurname('');
        setMobileNumber('');
        setEmailAddress('');
        setUserRoles([]);
        setPillarRoles([]);
        onClose();
        setRefresh(!refresh);
    };

    const IsvalidEmail = () => {
        if (validator.isEmail(emailAddress)) {
            return true;
        }
        setValidationError('Invalid Email Address')
        return false;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        await AccountAuth.UpdateUserRole(editId, userRoles.join(','), pillarRoles.join(','), AppSettings.App.NSPMonitoring).then((response) => {
            if (response.content.isSuccess) {
                toast.success(response.content.message, { position: "top-right", })
            } else {
                toast.error(response.content.message, { position: "top-right", });
            }
            setIsLoading(false);
            onResetFormAndClose();
        })
    };


    return (
        <Dialog open={open} fullWidth>
            <DialogTitle textAlign="center">{editId === 0 ? "Add New User" : "Update User Role"}</DialogTitle>
            <DialogContent>
                <div>Update user permissions here</div>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack sx={{ width: '100%', minWidth: { xs: '300px', sm: '360px', md: '400px' }, gap: '1.5rem', paddingTop: '10px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Roles</InputLabel>
                                    <Select multiple
                                        value={userRoles}
                                        onChange={handleChange}
                                        input={<OutlinedInput label="Roles" />}
                                        renderValue={(selected) => selected.join(', ')}>
                                        {roles.map((role) => (
                                            <MenuItem key={role.roleId} value={role.roleName} disabled={role.roleName === 'General' && userRoles.includes('General')}>
                                                <Checkbox color="primary" checked={userRoles.indexOf(role.roleName) > -1} />
                                                <ListItemText primary={role.roleName} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {displayPillars ?
                                <Grid item xs={12} sm={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>Pillars</InputLabel>
                                        <Select multiple
                                            value={pillarRoles}
                                            onChange={handleChangePillars}
                                            input={<OutlinedInput label="Pillars" />}
                                            renderValue={(selected) => selected.join(', ')}>
                                            {pillars.map((pillar) => (
                                                <MenuItem key={pillar.id} value={pillar.description}>
                                                    <FormControlLabel control={<Checkbox checked={pillarRoles.indexOf(pillar.description) > -1} />} label={pillar.description} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid> : <></>}

                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth label="First Name" value={firstName} onChange={e => setFirstName(e.target.value)} disabled={editId === 0 ? false : true} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth label="Surname" value={surname} onChange={e => setSurname(e.target.value)} disabled={editId === 0 ? false : true} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField required type="tel" fullWidth value={mobileNumber} onChange={e => setMobileNumber(e.target.value)} label="Mobile Number" disabled={editId === 0 ? false : true} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField required type="email" fullWidth value={emailAddress} onChange={e => setEmailAddress(e.target.value)} label="Email Address" disabled={editId === 0 ? false : true} />
                                {!IsvalidEmail && { validationError }}
                            </Grid>

                        </Grid>
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={onClose}>Cancel</Button>
                <Button color="primary" onClick={handleSubmit} variant="contained" disabled={isLoading || !isFormValid()}>
                    {editId === 0 ? "Add New User" : "Update Role"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UsersPendingApprovals