import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { Stack, TextField, Tooltip, IconButton, Button, FormControl } from '@mui/material';
import { Box, Dialog, DialogTitle, MenuItem, InputLabel } from '@mui/material';
import { DialogActions, DialogContent, Select, Grid } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';
import NSPMonitorAuth from '../../../../state/APIConnect/RaiseMyhand/NSPMonitor';
import Header from '../../../../components/Table/Header';
import CommonAuth from '../../../../state/APIConnect/Common';
import GoogleAddressAutoComplete from '../../../../components/AutoCompleteAddress/GoogleAddressAutoComplete';

const InstitutionPage = () => {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const confirm = useConfirm();
    const [refresh, setRefresh] = useState(false);

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        try {
            let data = {
                "id": 0,
                "name": "string",
                "description": "string",
                "categoryId": 0,
                "provinceId": 0,
                "districtMunicipalityId": 0,
                "localId": 0,
                "phoneNumber": "string",
                "alternativeNumber": "string",
                "emailAddress": "user@example.com",
                "alternativeEmailAddress": "string"
            }
            const resp = await NSPMonitorAuth.UpsertInstitution(data);
            if (!resp.is_error || resp.content.isSuccess) {
                toast.success(resp.content.message, { position: "top-right", });
                if (!Object.keys(validationErrors).length) {
                    tableData[row.index] = values;
                    setTableData([...tableData]);
                    exitEditingMode();
                }
            } else {
                toast.error(resp.content.message, { position: "top-right", })
            }
        } catch (error) {
            toast.error(error, { position: "top-right", })
        }
    };

    const handleDeleteRow = useCallback((row) => {
        confirm({
            title: `Are you sure you want to delete?`, description: `This will permanently delete: 
            ${row.original.name}.`, cancellationText: `No`, confirmationText: `Yes`
        }).then(async () => {
            setIsLoading(true);
            const resp = await NSPMonitorAuth.DeleteInstitutionById(row.original.id);
            setIsLoading(false);
            if (resp.content === "") {
                toast.error("Error occured while attemping to Delele.Please Contact Admininstrator", { position: "top-right", })
            }
            if (resp.content?.isSuccess) {
                setRefresh(!refresh);
                toast.success(resp.content.message, { position: "top-right", });
            } else {
                toast.error(resp.content.message, { position: "top-right", })
            }
        }).catch(error => {
            console.log("Deletion cancelled." + error)
            toast.error(error, { position: "top-right", })
        });
        tableData.splice(row.index, 1);
        setTableData([...tableData]);
    }, [confirm, refresh, tableData]);

    const GetAllIntitutions = async () => {
        if (!tableData.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }
        try {
            const res = await NSPMonitorAuth.GetAllInstitutions(null, pagination.pageIndex, pagination.pageSize);
            setTableData(res.content.items);
            setRowCount(res.content.totalItems)
        } catch (error) {
            console.error(error);
            setIsError(true);
            return;
        } finally {
            setIsError(false);
            setIsLoading(false);
            setIsRefetching(false);
        }
    }


    useEffect(() => {
        GetAllIntitutions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData.length, pagination.pageIndex, pagination.pageSize, refresh])



    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid = true;
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 10,
            },
            {
                accessorKey: 'name',
                header: 'Name',
                size: 300,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'description',
                header: 'Description',
                size: 350,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'category',
                header: 'Category',
                size: 10,
                enableEditing: false, //disable editing on this column
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },

            {
                accessorKey: 'province',
                header: 'Province',
                size: 10,
                enableEditing: false, //disable editing on this column
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            
            {
                accessorKey: 'districtMunicipality',
                header: 'District Municipality',
                size: 20,
                enableEditing: false, //disable editing on this column
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            
            {
                accessorKey: 'phoneNumber',
                header: 'Phone Number',
                size: 20,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'email',
                header: 'Email Address',
                size: 20,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
        ], [getCommonEditTextFieldProps],
    );

    return (
        <>
            <MaterialReactTable displayColumnDefOptions={{ 'mrt-row-actions': { muiTableHeadCellProps: { align: 'center', }, size: 120, }, }}
                columns={columns}
                muiTable
                data={tableData}
                editingMode="modal" //default
                initialState={{ columnVisibility: { id: false } }} //hide firstName column by default
                enableColumnOrdering
                enableEditing
                enablePagination
                rowCount={rowCount}
                manualPagination
                onPaginationChange={setPagination}
                muiTableBodyCellEditTextFieldProps={{ multiline: true }}
                onEditingRowSave={handleSaveRowEdits}
                onEditingRowCancel={handleCancelRowEdits}
                state={{
                    isLoading,
                    pagination,
                    showAlertBanner: isError,
                    showProgressBars: isRefetching,
                }}
                positionGlobalFilter="left"
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton onClick={() => table.setEditingRow(row)}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Delete">
                            <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                renderTopToolbarCustomActions={() => (
                    <>
                        <Button
                            color="primary"
                            onClick={() => setCreateModalOpen(true)}
                            variant="contained" >
                            Add Institution
                        </Button>
                        <Header subtitle="Institution" />
                    </>
                )} />

            <CreateNewInstitutionModal
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                refresh={refresh}
                setRefresh={() => setRefresh(!refresh)} />
        </>
    )
}

export const CreateNewInstitutionModal = ({ open, onClose, refresh, setRefresh }) => {
    const [provinceId, setProvinceId] = useState(0);
    const [categoryId, setCategoryId] = useState(0);
    const [districtMunicipalityId, setDistrictMunicipalityId] = useState(0);
    const [localsByDistrictMunicipalityId, setLocalsByDistrictMunicipalityId] = useState(0);
    const [corePurposeId, setCorePurposeId] = useState(0);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [mainOfficeaddress, setMainOfficeAddress] = useState('');
    const [alternativeNumber, setAlternativeNumber] = useState('');
    const [email, setEmail] = useState('');
    const [alternativeEmail, setAlternativeEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [pagination] = useState({ pageIndex: 0, pageSize: 100 });
    let platformId = 1;

    const [categories, setCategories] = useState([])
    const [provinces, setProvinces] = useState([])
    const [corePurposes, setCorePurposes] = useState([])
    const [districtMunicipalities, setDistrictMunicipalities] = useState([])
    const [localsByDistrictMunicipalities, setLocalsByDistrictMunicipalities] = useState([])

    const isFormValid = () => {
        let valid = true;
        if (name === '' || name.length < 5 || email === '') {
            valid = false;
        }
        if (categoryId === 0 || provinceId === 0 || districtMunicipalityId === 0) {
            valid = false;
        }
        return valid;
    }

    const GetAllProvinces = async () => {
        const res = await CommonAuth.GetAllProvinces();
        setProvinces(res.content);
    }

    const GetAllCategories = async () => {
        const res = await NSPMonitorAuth.GetAllCategories(null, pagination.pageIndex, pagination.pageSize);
        setCategories(res.content.items);
    }

    const GetDistrictMunicipalityByProvinceId = async (event) => {
        let proId = event.target.value;
        setProvinceId(proId)
        const res = await CommonAuth.GetDistrictMunicipalityByProvinceId(proId, null, pagination.pageIndex, pagination.pageSize);
        setDistrictMunicipalities(res.content.items);
    }

    const GetLocalsByDistrictMunicipalityId = async (event) => {
        let distId = event.target.value;
        setDistrictMunicipalityId(distId)
        const res = await CommonAuth.GetLocalsByDistrictMunicipalityId(distId, null, pagination.pageIndex, pagination.pageSize);
        setLocalsByDistrictMunicipalities(res.content.items);
    }

    const GetAllPillars = async () => {
        const res = await NSPMonitorAuth.GetAllPillars(platformId);
        setCorePurposes(res.content)
    }

    useEffect(() => {
        GetAllCategories();
        GetAllProvinces();
        GetAllPillars();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.pageIndex, pagination.pageSize])



    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            let data = {
                "id": 0,
                "name": name,
                "description": description,
                "categoryId": categoryId,
                "provinceId": provinceId,
                "corePurposeId": corePurposeId,
                "districtMunicipalityId": districtMunicipalityId,
                "localId": localsByDistrictMunicipalityId,
                "phoneNumber": phoneNumber,
                "alternativeNumber": alternativeNumber,
                "emailAddress": email,
                "alternativeEmailAddress": alternativeEmail,
                "address": mainOfficeaddress,
                "isConfirmed": true
            };

            const resp = await NSPMonitorAuth.UpsertInstitution(data);
            setIsLoading(false);
            if (!resp.is_error || resp.content.isSuccess) {
                toast.success(resp.content.message, { position: "top-right", })
                onClose();
                setRefresh(!refresh);
            } else {
                toast.error(resp.content.message, { position: "top-right", })
            }
        } catch (error) {
            setIsLoading(false);
            toast.error(error, { position: "top-right", })
        }

    };


    return (
        <>
            <Dialog open={open} fullWidth>
                <DialogTitle textAlign="center">Add New Institution</DialogTitle>
                <DialogContent>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <Stack sx={{ width: '100%', minWidth: { xs: '300px', sm: '360px', md: '400px' }, gap: '1.5rem', paddingTop: '10px' }}>

                            <FormControl fullWidth>
                                <InputLabel>Core Purpose</InputLabel>
                                <Select defaultValue={0} label="Core Purpose" value={corePurposeId} onChange={(e) => { setCorePurposeId(e.target.value) }} >
                                    <MenuItem value={0}><em>None</em></MenuItem>
                                    {corePurposes ? corePurposes.map(corePurpose => {
                                        return (<MenuItem key={corePurpose.id} value={corePurpose.id}> {corePurpose.description} </MenuItem>)
                                    }) : <MenuItem key={0} value={0}>{'No Core Purpose'} </MenuItem>}
                                </Select>
                            </FormControl>


                            <FormControl variant="outlined">
                                <InputLabel>Category *</InputLabel>
                                <Select defaultValue={0} label="Category *" value={categoryId} onChange={(e) => { setCategoryId(e.target.value) }}>
                                    <MenuItem value={0} defaultValue ><em>None</em></MenuItem>
                                    {categories ? categories.map(category => {
                                        return (<MenuItem key={category.id} value={category.id}> {category.name} </MenuItem>)
                                    }) : <MenuItem key={0} value={0}>{'No Category'} </MenuItem>}
                                </Select>
                            </FormControl>

                            <FormControl variant="outlined">
                                <InputLabel>Province *</InputLabel>
                                <Select defaultValue={0} label="Province *" value={provinceId} onChange={GetDistrictMunicipalityByProvinceId}>
                                    <MenuItem value={0} defaultValue ><em>None</em></MenuItem>
                                    {provinces ? provinces.map(province => {
                                        return (<MenuItem key={province.id} value={province.id}> {province.name} </MenuItem>)
                                    }) : <MenuItem key={0} value={0}>{'No Provinces'} </MenuItem>}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth>
                                <InputLabel>District Municipality *</InputLabel>
                                <Select defaultValue={0} label="District Municipality *" value={districtMunicipalityId} onChange={GetLocalsByDistrictMunicipalityId} >
                                    <MenuItem value={0}><em>None</em></MenuItem>
                                    {districtMunicipalities.map(districtMunicipality => {
                                        return (<MenuItem key={districtMunicipality.id} value={districtMunicipality.id}> {districtMunicipality.name} </MenuItem>)
                                    })}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth>
                                <InputLabel>Local District Municipality</InputLabel>
                                <Select defaultValue={0} label="Local District Municipality" value={localsByDistrictMunicipalityId} onChange={(e) => { setLocalsByDistrictMunicipalityId(e.target.value) }} >
                                    <MenuItem value={0}><em>None</em></MenuItem>
                                    {localsByDistrictMunicipalities.map(localdistrictMunicipality => {
                                        return (<MenuItem key={localdistrictMunicipality.id} value={localdistrictMunicipality.id}> {localdistrictMunicipality.name} </MenuItem>)
                                    })}
                                </Select>
                            </FormControl>

                            <TextField fullWidth label="Name" value={name} onChange={e => setName(e.target.value)} />

                            <TextField fullWidth multiline rows={2} label="Description" value={description} onChange={e => setDescription(e.target.value)} />

                            <FormControl fullWidth>
                                <GoogleAddressAutoComplete value={mainOfficeaddress} onChange={setMainOfficeAddress} />
                            </FormControl>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth label="Phone Number" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth label="Alternative Number" value={alternativeNumber} onChange={e => setAlternativeNumber(e.target.value)} />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth label="Email" value={email} onChange={e => setEmail(e.target.value)} />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth label="Alternative Email" value={alternativeEmail} onChange={e => setAlternativeEmail(e.target.value)} />
                                </Grid>
                            </Grid>

                        </Stack>
                    </form>
                </DialogContent>
                <DialogActions sx={{ p: '1.25rem' }}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button color="primary" onClick={handleSubmit} variant="contained" disabled={isLoading || !isFormValid()}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default InstitutionPage