import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CircularProgress, Typography, Container, Grid, Button, Box } from '@mui/material';
import AccountAuth from '../../state/APIConnect/Account';
import { toast } from 'react-toastify';

const ConfirmEmailPage = () => {
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const userId = queryParams.get('userId');
        const code = queryParams.get('code');

        if (userId && code) {
            confirmEmail(userId, code);
        } else {
            setMessage('Invalid confirmation link.');
            setLoading(false);
        }
    }, [location]);

    const confirmEmail = async (userId, code) => {
        let data = { "userId": userId, "code": code };
        await AccountAuth.confirmEmail(data).then((response) => {
            if (response.content !== null) {
                toast.success(response.content.message, { position: "top-right", })
            } else {
                toast.error(response.error_content.message, { position: "top-right", })
            }
            setLoading(false);
        })
    };

    const handleLoginRedirect = () => {
        navigate('/login');
    };

    return (
        <Box m="20px">
            <Grid container component="main" sx={{ height: '90vh' }}>
                <Container maxWidth="sm" style={{ textAlign: 'center', padding: '50px' }}>
                    {loading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            <Typography variant="body1" gutterBottom>
                                You can now log in to your account using the link below.
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleLoginRedirect}
                                style={{ marginTop: '20px' }}>
                                Go to Login
                            </Button>
                        </>
                    )}
                </Container>
            </Grid >
        </Box>
    )
}

export default ConfirmEmailPage