import React, { useEffect, useState } from 'react';
import { Stack, Typography, Box, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import AccountAuth from '../../state/APIConnect/Account';
import AccountInfo from '../../components/Account/AccountInfo';
import AccountForm from '../../components/Account/AccountForm';

const AccountPage = () => {
    const [userInfo, setUserInfo] = useState({});
    const [loading, setLoading] = useState(true);

    const GetUserInfo = async () => {
        const response = await AccountAuth.GetUserInfo();
        setUserInfo(response.content);
        setLoading(false)
    }

    useEffect(() => {
        GetUserInfo();
    }, [])


    return (
        <Box m="20px">
            <Stack spacing={3}>
                <div>
                    <Typography variant="h4">Account</Typography>
                </div>

                <Grid container spacing={3}>
                    {loading ? (
                        <CircularProgress size={24} sx={{ color: 'primary', position: 'absolute', top: '50%', left: '50%', marginTop: '12px', marginLeft: '-12px', }} />
                    ) : (
                        <>
                            <Grid lg={4} md={6} xs={12}>
                                <AccountInfo userInfo={userInfo} />
                            </Grid>
                            <Grid lg={8} md={6} xs={12}>
                                <AccountForm userInfo={userInfo} />
                            </Grid>
                        </>
                    )}
                </Grid>
            </Stack>
        </Box>
    )
}

export default AccountPage