import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Button, FormControl, InputLabel, Card } from '@mui/material'
import { MenuItem, Select, TextField, Checkbox, Skeleton, Stack } from '@mui/material';
import NSPMonitorAuth from '../../../../state/APIConnect/RaiseMyhand/NSPMonitor';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { toast } from 'react-toastify';

const EditMonthlyAchievement = () => {
    const { achievementId } = useParams();
    const navigate = useNavigate();

    const [pillars, setPillars] = useState([]);
    const [interventions, setInterventions] = useState([]);
    const [activities, setActivities] = useState([]);
    const [status, setStatus] = useState([]);
    const [obtacles, setObtacles] = useState([]);
    const [pagination] = useState({ pageIndex: 0, pageSize: 100 });
    const [isLoading, setIsLoading] = useState(false);
    const [item, setItem] = useState({});

    const [pillarId, setPillarId] = useState(0);
    const [interventionId, setInterventionId] = useState(0);
    const [activityId, setActivityId] = useState(0);
    const [statusId, setStatusId] = useState(0);
    const [obtacleId, setObtacleId] = useState(0);
    const [reportPeriod, setReportPeriod] = useState(null);
    const [progress, setProgress] = useState(0);
    const [progressNative, setProgressNative] = useState("");
    const [obtacleNarative, setObtacleNarative] = useState("");
    const [isComplete, setIsComplete] = useState(false);


    const GetAllPillars = async () => {
        let platformId = 1;
        const res = await NSPMonitorAuth.GetAllPillars(platformId);
        setPillars(res.content);
    }

    const GetAllInterventions = async () => {
        const res = await NSPMonitorAuth.GetInterventionsByPillarId(pillarId, null, pagination.pageIndex, pagination.pageSize);
        setInterventions(res.content.items)
    }

    const GetAllActivities = async () => {
        const res = await NSPMonitorAuth.GetActivityByInterventionId(interventionId, null, pagination.pageIndex, pagination.pageSize);
        setActivities(res.content.items)
    }

    const GetAllStatus = async () => {
        const res = await NSPMonitorAuth.GetAllStatus();
        setStatus(res.content)
    }

    const GetAllDeviationReason = async () => {
        const res = await NSPMonitorAuth.GetAllDeviationReason();
        setObtacles(res.content)
    }

    

    useEffect(() => {
        const GetAchievementById = async () => {
            if (!obtacles.length) {
                setIsLoading(true);
            }
            const res = await NSPMonitorAuth.GetAchievementById(achievementId);
            if (res.content === '') {
                console.log('Content is null empty');
            }
            else {
                let data = res.content;
                setItem(data);
                setReportPeriod(dayjs("2023"))
                setPillarId(data.pillarId)
                setInterventionId(data.interventationId)
                setActivityId(data.activitiesId)
                setStatusId(data.statusId)
                setObtacleId(data.deviationReasonId)
                setObtacleNarative(data.correctiveMeasures);
                setProgressNative(data.progressNative);
                setIsComplete(data.isComplete);
            }
            setIsLoading(false);
        }

        GetAchievementById()
        GetAllPillars();
        GetAllInterventions();
        GetAllActivities();
        GetAllStatus();
        GetAllDeviationReason();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [achievementId, pillarId]);

    const GetInterventionByPillarId = async (event) => {
        let pId = event.target.value;
        setPillarId(pId);
        const res = await NSPMonitorAuth.GetInterventionsByPillarId(pId, null, pagination.pageIndex, pagination.pageSize);
        setInterventions(res.content.items)
    }

    const GetActivitiesByInterventionId = async (event) => {
        let iId = event.target.value;
        setInterventionId(iId);
        const res = await NSPMonitorAuth.GetActivityByInterventionId(iId, null, pagination.pageIndex, pagination.pageSize);
        setActivities(res.content.items)
    }

    const isFormValid = () => {
        let valid = true;
        if (pillarId === 0 || interventionId === 0 || activityId === 0 ||
            obtacleId === 0 || statusId === 0) {
            valid = false;
        }
        if (progressNative === '' || obtacleNarative === '') {
            valid = false;
        }
        return valid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setIsLoading(true);
            let data = {
                "id": achievementId,
                "pillarId": pillarId,
                "interventationId": interventionId,
                "activitiesId": activityId,
                "subActivitiesId": 1,
                "deviationReasonId": obtacleId,
                "statusId": statusId,
                "progress": progress,
                "progressNative": progressNative,
                "correctiveMeasures": obtacleNarative,
                "isComplete": isComplete,
                "reportDate": reportPeriod,
                "reportYear": dayjs(reportPeriod).year()
            }
            const res = await NSPMonitorAuth.UpsertAchievement(data);
            setIsLoading(false);
            if (res.content?.isSuccess) {
                toast.success(res.content.message, { position: "top-right", })
                handleBack();
            } else {
                toast.error(res.content.message, { position: "top-right", })
            }
        } catch (error) {
            setIsLoading(false);
            toast.error(error, { position: "top-right", })
        }
    }

    const handleChange = (newValue) => {
        setReportPeriod(newValue);
    };

    const handleBack = () => {
        navigate("/monthlyAchievement");
    }


    return (
        <Card sx={{ minWidth: 275 }}>
            <Box m="0.5rem 2.5rem">
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <h1>Update Monthly Report</h1>
                    {
                        item ? (
                            <>
                                <Grid container spacing={2}>

                                    <Grid item xs={12} sm={4}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    value={dayjs(reportPeriod)}
                                                    onChange={handleChange}
                                                    label={'Report Period'}
                                                    views={['month', 'year']} />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={8}>
                                        <FormControl fullWidth>
                                            <InputLabel>Pillar</InputLabel>
                                            <Select defaultValue={0} label="Pillar" value={pillarId === -1 ? '' : pillarId} onChange={GetInterventionByPillarId} >
                                                <MenuItem value={0}><em>None</em></MenuItem>
                                                {pillars.map(pillar => {
                                                    return (<MenuItem key={pillar.id} value={pillar.id}> {pillar.name} </MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel>Intervention</InputLabel>
                                            <Select defaultValue={0} label="Intervention" value={interventionId} onChange={GetActivitiesByInterventionId} >
                                                <MenuItem value={0}><em>None</em></MenuItem>
                                                {interventions.map(intervention => {
                                                    return (<MenuItem key={intervention.id} value={intervention.id}> {intervention.name} </MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel>Activity</InputLabel>
                                            <Select defaultValue={0} label="Activity" value={activityId} onChange={(e) => setActivityId(e.target.value)} >
                                                <MenuItem value={0}><em>None</em></MenuItem>
                                                {activities.map(activity => {
                                                    return (<MenuItem key={activity.id} value={activity.id}> {activity.name} </MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <InputLabel>Status</InputLabel>
                                            <Select defaultValue={0} label="Status" value={statusId} onChange={(e) => { setStatusId(e.target.value) }} >
                                                <MenuItem value={0}><em>None</em></MenuItem>
                                                {status.map(status => {
                                                    return (<MenuItem key={status.id} value={status.id}> {status.name} </MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <InputLabel>Deviation Reason</InputLabel>
                                            <Select defaultValue={0} label="Deviation Reason" value={obtacleId} onChange={(e) => { setObtacleId(e.target.value) }} >
                                                <MenuItem value={0}><em>None</em></MenuItem>
                                                {obtacles.map(obtacle => {
                                                    return (<MenuItem key={obtacle.id} value={obtacle.id}> {obtacle.name} </MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField required
                                            multiline
                                            rows={4}
                                            fullWidth
                                            value={progressNative}
                                            onChange={e => setProgressNative(e.target.value)}
                                            label="Progress Narrative" />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField required
                                            multiline
                                            rows={2}
                                            fullWidth
                                            value={obtacleNarative}
                                            onChange={e => setObtacleNarative(e.target.value)}
                                            label="Corrective Measures" />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Checkbox checked={isComplete}
                                            onChange={e => setIsComplete(e.target.checked)}
                                            inputProps={{ 'aria-label': 'controlled' }} />Report Completed
                                    </Grid>

                                </Grid>

                                <Grid container>
                                    <Grid item xs>
                                        <Button onClick={() => handleBack()}
                                            color='primary'
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}>
                                            Back
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button disabled={isLoading || !isFormValid()}
                                            type="submit"
                                            color='primary'
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}>
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        ) : (
                            <Stack spacing={1}>
                                {/* For variant="text", adjust the height via font-size */}
                                <Skeleton variant="text" sx={{ fontSize: '4rem' }} />

                                <Skeleton variant="text" sx={{ fontSize: '4rem' }} />
                            </Stack>
                        )
                    }

                </Box>
            </Box>
        </Card>
    )
}

export default EditMonthlyAchievement