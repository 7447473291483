import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Button, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        padding: theme.spacing(4),
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
    },
    title: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(2),
    },
    message: {
        marginBottom: theme.spacing(4),
        color: theme.palette.text.secondary,
    },
    button: {
        marginTop: theme.spacing(2),
    },
}));

const NotFound = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleHomeRedirect = () => {
        navigate('/');
    };

    return (
        <Container className={classes.root}>
            <Typography variant="h1" className={classes.title}>
                404
            </Typography>
            <Typography variant="h5" className={classes.message}>
                Oops! The page you're looking for doesn't exist.
            </Typography>
            <Button variant="contained" color="primary" onClick={handleHomeRedirect} className={classes.button}>
                Go to Home
            </Button>
        </Container>
    );
};

export default NotFound;
