import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Button, FormControl, InputLabel, Card } from '@mui/material'
import { MenuItem, Select, TextField, Checkbox, Typography } from '@mui/material';
import NSPMonitorAuth from '../../../../state/APIConnect/RaiseMyhand/NSPMonitor';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { toast } from 'react-toastify';
import CommonAuth from '../../../../state/APIConnect/Common';
import QuartelyViewComponent from './QuartelyViewComponent';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


const AddQuarterlyAchievement = () => {
    const navigate = useNavigate();
    const [pillars, setPillars] = useState([]);
    const [interventions, setInterventions] = useState([]);
    const [activities, setActivities] = useState([]);
    const [reportCycles, setReportCycles] = useState([]);
    const [reportQuarters, setReportQuarters] = useState([]);
    const [status, setStatus] = useState([]);
    const [obtacles, setObtacles] = useState([]);
    const [monthlyReport, setMonthlyReport] = useState([]);
    const [pagination] = useState({ pageIndex: 0, pageSize: 100 });
    const [isLoading, setIsLoading] = useState(false);

    const [showReport, setShowReport] = useState(false);

    const [reportCycleId, setReportCycleId] = useState(0);
    const [reportQuarterId, setReportQuarterId] = useState(0);
    const [reportYear, setReportYear] = useState(0);
    const [pillarId, setPillarId] = useState(0);
    const [statusId, setStatusId] = useState(0);
    const [obtacleId, setObtacleId] = useState(0);
    const [interventionId, setInterventionId] = useState(0);
    const [activityId, setActivityId] = useState(0);
    const [isComplete, setIsComplete] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const [progressNative, setProgressNative] = useState("");
    const [obtacleNarative, setObtacleNarative] = useState("");

    useEffect(() => {
        const GetAllCyclics = async () => {
            const res = await CommonAuth.GetAllCyclics();
            setReportCycles(res.content)
        }

        const GetAllPillars = async () => {
            let platformId = 1;
            const res = await NSPMonitorAuth.GetPillarsForInstitutions();
            setPillars(res.content);
        }

        const GetAllStatus = async () => {
            const res = await NSPMonitorAuth.GetAllStatus();
            setStatus(res.content)
        }

        const GetAllDeviationReason = async () => {
            const res = await NSPMonitorAuth.GetAllDeviationReason();
            setObtacles(res.content)
        }

        GetAllCyclics();
        GetAllPillars();
        GetAllStatus();
        GetAllDeviationReason();
    }, [])

    const handleFileChange = (e) => {
        const files = e.target.files;
        setSelectedFiles(Array.from(files));
    };

    const GetReportQuartyByCycleId = async (event) => {
        let cId = event.target.value;
        setReportCycleId(cId);
        const res = await CommonAuth.GetAllCyclicByCyclicTypeId(cId);
        setReportQuarters(res.content)
    }

    const GetInterventionByPillarId = async (event) => {
        let pId = event.target.value;
        setPillarId(pId);
        const res = await NSPMonitorAuth.GetInterventionsByPillarId(pId, null, pagination.pageIndex, pagination.pageSize);
        setInterventions(res.content.items)
    }

    const GetActivitiesByInterventionId = async (event) => {
        let iId = event.target.value;
        setInterventionId(iId);
        const res = await NSPMonitorAuth.GetActivityByInterventionId(iId, null, pagination.pageIndex, pagination.pageSize);
        setActivities(res.content.items)
    }

    const isFormValid = () => {
        let valid = true;
        if (pillarId === 0 || interventionId === 0 || activityId === 0 || reportQuarterId === 0) {
            valid = false;
        }
        if (progressNative === '' || obtacleNarative === '') {
            valid = false;
        }
        return valid;
    }

    const handleReportYearChange = (newValue) => {
        let toDate = newValue.toDate();
        let toYear = dayjs(toDate).year();
        setReportYear(toYear);
    };

    const GetQaurterlyReportByActivityId = async (event) => {
        let aId = event.target.value;
        setActivityId(aId);
        const res = await NSPMonitorAuth.GetQaurterlyReport(reportQuarterId, reportYear, pillarId, interventionId, aId);
        setMonthlyReport(res.content);
        setShowReport(true)
    }

    const handleBack = () => {
        navigate("/quarterlyAchievement");
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setIsLoading(true);
            const formData = new FormData();
            formData.append('id', 0);
            formData.append('cyclicTypeId', reportCycleId);
            formData.append('cyclicId', reportQuarterId);
            formData.append('pillarId', pillarId);
            formData.append('interventationId', interventionId);
            formData.append('activitiesId', activityId);
            formData.append('progress', 0);

            formData.append('deviationReasonId', obtacleId);
            formData.append('statusId', statusId);

            formData.append('progressNative', progressNative);
            formData.append('correctiveMeasures', obtacleNarative);
            formData.append('reportYear', reportYear);
            formData.append('isComplete', isComplete);
            formData.append('approveOrDecline', 'approve');
            formData.append('formFiles', selectedFiles);
            for (const file of selectedFiles) {
                formData.append('formFiles', file);
            }

            const res = await NSPMonitorAuth.UpsertQaurterlyReport(formData);
            setIsLoading(false);
            if (res.content?.isSuccess) {
                toast.success(res.content.message, { position: "top-right", })
                setReportCycleId(0);
                setReportQuarterId(0);
                setPillarId(0);
                setInterventionId(0);
                setActivityId(0);
                setProgressNative("");
                setObtacleNarative("");
                setShowReport(false)
            } else {
                toast.success(res.content.message, { position: "top-right", })
                handleBack();
            }

        } catch (error) {
            setIsLoading(false);
            toast.error(error, { position: "top-right", })
        }
    }

    return (
        <Card sx={{ minWidth: 275 }}>
            <Box m="0.5rem 2.5rem">
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <h1>Quarterly Report</h1>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <InputLabel>Report Cycle</InputLabel>
                                <Select defaultValue={0} label="Report Cycle" value={reportCycleId} onChange={GetReportQuartyByCycleId} >
                                    <MenuItem value={0}><em>None</em></MenuItem>
                                    {reportCycles.map(reportCycle => {
                                        return (<MenuItem key={reportCycle.id} value={reportCycle.id}> {reportCycle.name} </MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <InputLabel>Report Quarter</InputLabel>
                                <Select defaultValue={0} label="Report Quarter" value={reportQuarterId} onChange={(e) => { setReportQuarterId(e.target.value) }} >
                                    <MenuItem value={0}><em>None</em></MenuItem>
                                    {reportQuarters.map(reportQuarter => {
                                        return (<MenuItem key={reportQuarter.id} value={reportQuarter.id}> {reportQuarter.description} </MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker onChange={handleReportYearChange}
                                        label={'Report Year'}
                                        views={['year']} />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel>Pillar</InputLabel>
                                <Select defaultValue={0} label="Pillar" value={pillarId} onChange={GetInterventionByPillarId} >
                                    <MenuItem value={0}><em>None</em></MenuItem>
                                    {pillars.map(pillar => {
                                        return (<MenuItem key={pillar.id} value={pillar.id}> {pillar.name} </MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel>Intervention</InputLabel>
                                <Select defaultValue={0} label="Intervention" value={interventionId} onChange={GetActivitiesByInterventionId} >
                                    <MenuItem value={0}><em>None</em></MenuItem>
                                    {interventions.map(intervention => {
                                        return (<MenuItem key={intervention.id} value={intervention.id}> {intervention.name} </MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth sm={4}>
                                <InputLabel>Activity</InputLabel>
                                <Select defaultValue={0} label="Activity" value={activityId} onChange={GetQaurterlyReportByActivityId}>
                                    <MenuItem value={0}><em>None</em></MenuItem>
                                    {activities.map(activity => {
                                        return (<MenuItem key={activity.id} value={activity.id}> {activity.name} </MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                        {showReport && (
                            <>
                                <QuartelyViewComponent monthlyReport={monthlyReport} />

                                <Grid item xs={12}>
                                    <Typography component="div">
                                        <Box sx={{ fontWeight: 'bold', m: 1 }}>Quarterly Summary</Box>
                                    </Typography>
                                </Grid>

                                {/* {monthlyReport.length > 0 ? (
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <TextField required
                                                multiline
                                                rows={4}
                                                fullWidth
                                                value={progressNative}
                                                onChange={e => setProgressNative(e.target.value)}
                                                label="Progress Narrative" />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField required
                                                multiline
                                                rows={4}
                                                fullWidth
                                                value={obtacleNarative}
                                                onChange={e => setObtacleNarative(e.target.value)}
                                                label="Corrective Measures" />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Button component="label" variant="contained" onChange={handleFileChange} startIcon={<CloudUploadIcon />} >
                                                Upload file <input type="file" onChange={handleFileChange} hidden multiple />
                                            </Button>
                                            {selectedFiles.length > 0 && (
                                                <div>
                                                    <p>Selected Files:</p>
                                                    <ul>
                                                        {selectedFiles.map((file, index) => (
                                                            <li key={index}>{file.name}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Checkbox checked={isComplete}
                                                onChange={e => setIsComplete(e.target.checked)}
                                                inputProps={{ 'aria-label': 'controlled' }} />Report Completed
                                        </Grid>
                                    </>
                                ) : (
                                    <Grid item xs={12} >
                                        <h1>No data available</h1>
                                    </Grid>
                                )} */}

                                <>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <InputLabel>Status</InputLabel>
                                            <Select defaultValue={0} label="Status" value={statusId} onChange={(e) => { setStatusId(e.target.value) }} >
                                                <MenuItem value={0}><em>None</em></MenuItem>
                                                {status.map(status => {
                                                    return (<MenuItem key={status.id} value={status.id}> {status.name} </MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <InputLabel>Deviation Reason</InputLabel>
                                            <Select defaultValue={0} label="Deviation Reason" value={obtacleId} onChange={(e) => { setObtacleId(e.target.value) }} >
                                                <MenuItem value={0}><em>None</em></MenuItem>
                                                {obtacles.map(obtacle => {
                                                    return (<MenuItem key={obtacle.id} value={obtacle.id}> {obtacle.name} </MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField required
                                            multiline
                                            rows={4}
                                            fullWidth
                                            value={progressNative}
                                            onChange={e => setProgressNative(e.target.value)}
                                            label="Progress Narrative" />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField required
                                            multiline
                                            rows={4}
                                            fullWidth
                                            value={obtacleNarative}
                                            onChange={e => setObtacleNarative(e.target.value)}
                                            label="Corrective Measures" />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button component="label" variant="contained" onChange={handleFileChange} startIcon={<CloudUploadIcon />} >
                                            Upload file <input type="file" onChange={handleFileChange} hidden multiple />
                                        </Button>
                                        {selectedFiles.length > 0 && (
                                            <div>
                                                <p>Selected Files:</p>
                                                <ul>
                                                    {selectedFiles.map((file, index) => (
                                                        <li key={index}>{file.name}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Checkbox checked={isComplete}
                                            onChange={e => setIsComplete(e.target.checked)}
                                            inputProps={{ 'aria-label': 'controlled' }} />Report Completed
                                    </Grid>
                                </>
                            </>
                        )}

                        <Grid container>
                            <Grid item xs>
                                <Button onClick={() => handleBack()}
                                    color='primary'
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}>
                                    Back to List
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button disabled={isLoading || !isFormValid()}
                                    type="submit"
                                    color='primary'
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Card>
    )
}

export default AddQuarterlyAchievement