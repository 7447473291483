import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Tooltip, IconButton, Button } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import NSPMonitorAuth from '../../../../state/APIConnect/RaiseMyhand/NSPMonitor';
import Header from '../../../../components/Table/Header';
import { useNavigate } from 'react-router-dom';

const DoneAchievements = () => {
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const navigate = useNavigate();


    const handleAddRow = () => {
        navigate("/monthlyAchievement/create");
    }

    const handleEditRow = (id) => {
        navigate("/MonthlyAchievement/edit/" + id);
    }

    const handleDeleteRow = (id) => {
        navigate("" + id);
    }

    const GetAllAchievements = async () => {
        let status = 'inprogress'; //completed, inprogress, done
        if (!tableData.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }
        try {
            const res = await NSPMonitorAuth.GetAllAchievementsByStatus(null, pagination.pageIndex, pagination.pageSize, status);
            setTableData(res.content.items);
            setRowCount(res.content.totalItems)
        } catch (error) {
            console.error(error);
            setIsError(true);
            return;
        } finally {
            setIsError(false);
            setIsLoading(false);
            setIsRefetching(false);
        }
    }

    useEffect(() => {
        GetAllAchievements();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData.length, pagination.pageIndex, pagination.pageSize])

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid = true;
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 10,
            },
            {
                accessorKey: 'pillarName',
                header: 'Pillar',
                size: 150,
                enableEditing: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'interventationName',
                header: 'Interventation',
                size: 250,
                enableEditing: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'activityName',
                header: 'Activity',
                size: 350,
                enableEditing: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'progressNative',
                header: 'Progress Native',
                size: 450,
                enableEditing: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'correctiveMeasures',
                header: 'Corrective Measures',
                size: 300,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'dateCreated',
                header: 'Date Created',
                size: 20,
                Cell: ({ cell }) => {
                    const date = new Date(cell.getValue());
                    return date.toDateString();
                },
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },

        ],
        [getCommonEditTextFieldProps],
    );

    return (
        <MaterialReactTable displayColumnDefOptions={{ 'mrt-row-actions': { muiTableHeadCellProps: { align: 'center', }, size: 120, }, }}
            columns={columns}
            muiTable
            data={tableData}
            initialState={{ columnVisibility: { id: true } }} //hide firstName column by default
            enableColumnOrdering
            enableEditing
            enablePagination
            rowCount={rowCount}
            manualPagination
            onPaginationChange={setPagination}
            muiTableBodyCellEditTextFieldProps={{ multiline: true }}
            // onEditingRowSave={handleSaveRowEdits}
            // onEditingRowCancel={handleCancelRowEdits}
            state={{
                isLoading,
                pagination,
                showAlertBanner: isError,
                showProgressBars: isRefetching,
            }}
            positionGlobalFilter="left"
            renderRowActions={({ row, table }) => (
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <Tooltip arrow placement="left" title="Edit">
                        <IconButton onClick={() => handleEditRow(row.original.id)}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip arrow placement="right" title="Delete">
                        <IconButton color="error" onClick={() => handleDeleteRow(row.original.id)}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </Box>
            )}
            renderTopToolbarCustomActions={() => (
                <>
                    <Button
                        color="primary"
                        onClick={() => handleAddRow()}
                        variant="contained" >
                        Add Monthly Achievement
                    </Button>
                    <Header subtitle="Monthly Achievement" />
                </>
            )} />
    )
}

export default DoneAchievements